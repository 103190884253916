<template>
  <App
    title="创建勘测单"
    left-text="取消"
  >
    <Form v-if="step === 0">
      <CellGroup>
        <Field
          v-model="formData.orderNo"
          label="选择订单"
          required
          readonly
          clickable
          @click="pickerShow('orderNo')"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
        <template v-if="selectOrderCompletion">
          <Cell
            title="订单状态"
            :value="orderInfo.status"
          />
          <Cell
            title="基础车型"
            :value="orderInfo.carType"
          />
          <Cell
            title="购车人姓名"
            :value="orderInfo.name"
          />
          <Cell
            title="购车人手机号"
            :value="orderInfo.mobile"
          />
        </template>
      </CellGroup>
    </Form>
    <Form v-if="step === 1">
      <CellGroup>
        <Field
          v-model="formData.name"
          label="联系人姓名"
          required
          maxlength="20"
          placeholder="Please enter联系人姓名"
          input-align="right"
        />
        <Field
          v-model="formData.phone"
          label="联系人电话"
          required
          maxlength="11"
          placeholder="Please enter联系人电话"
          input-align="right"
        />
        <Field
          v-model="formData.surveyResults.label"
          label="预勘测结果"
          required
          readonly
          clickable
          @click="pickerShow('surveyResults')"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
        <Field
          v-model="formData.city"
          label="所在地区"
          required
          readonly
          clickable
          @click="pickerShow('city')"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
        <Field
          v-model="formData.address"
          type="textarea"
          label="详细地址"
          label-width="100%"
          required
          maxlength="100"
          placeholder="Please enter详细地址"
        />
      </CellGroup>
    </Form>
    <Form
      ref="form-2"
      v-if="step === 2"
    >
      <CellGroup>
        <Field
          v-model="formData.appointmentTime"
          label="预约时间"
          required
          readonly
          clickable
          @click="appointmentTime.show = true"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
        <!-- <Field label="预约时间"
               label-width="100%"
               required>
          <template #input>
            <weekSlider style="width: 100%"
                        todayTxtColor='#DA3738'
                        activeBgColor='#ffffff'
                        activeTxtColor='#333'
                        :showYear="true"
                        :isAmPm="isAm"
                        :disabled='disabledDay'
                        :defaultDate="defaultDay"
                        :currentTime.sync="currentTime">
            </weekSlider>
          </template>
        </Field> -->
        <Field
          v-model="formData.serviceStar.label"
          label="服务明星"
          required
          readonly
          clickable
          @click="pickerShow('serviceStar')"
          input-align="right"
          placeholder="请选择"
          right-icon="arrow"
        />
      </CellGroup>
    </Form>
    <Footer
      slot="footer"
      :step="step"
      :maxStep="2"
      @on-last="lastStep"
      @on-next="nextStep"
      @on-save="onSave"
      @on-cancel="onCancel"
    />
    <Popup
      v-model="picker.show"
      position="bottom"
    >
      <Picker
        show-toolbar
        value-key="label"
        :loading="loading"
        :columns="picker.columns"
        @confirm="pickerConfirm"
        @cancel="picker.show = false"
      />
    </Popup>
    <Popup
      v-model="appointmentTime.show"
      position="bottom"
      class="cu-popup"
    >
      <DatetimePicker
        :show-toolbar="true"
        type="date"
        title=""
        class="appointment-date"
        v-model="appointmentTime.currentDate"
        :min-date="appointmentTime.minDate"
        :max-date="appointmentTime.maxDate"
        @confirm="appointmentTimeConfirm"
        @cancel="appointmentTime.show = false"
      />
      <!-- <Picker :columns="appointmentTime.columns"
              @change="changeAppointmentTimePicker"
              class="phase" /> -->
    </Popup>
  </App>
</template>

<script>
import dayjs from 'dayjs'
import { Form, Field, CellGroup, Cell, Popup, Picker, Toast, DatetimePicker } from 'vant'
import { orderList, getDetail, allStart, createSurvey } from '@/api/order/index'
import { getAreaTree } from '@/api/common/index'
import { orderMessage } from '@/api/clue/index'
import { orderStatusAttr } from '@/utils/config'
import Footer from './component/footer/index'
// import weekSlider from '_c/weekSlider'
import 'vant/lib/form/style'
import 'vant/lib/cell-group/style'
import 'vant/lib/cell/style'
import 'vant/lib/field/style'
import 'vant/lib/popup/style'
import 'vant/lib/picker/style'
import 'vant/lib/datetime-picker/style'
import './index.less';

export default {
  name: 'createSurveyOrder',
  components: { Form, Field, CellGroup, Cell, Popup, Picker, DatetimePicker, Footer },
  data () {
    return {
      uid: '',
      loading: true,
      formData: {
        uid: '',
        orderNo: '',
        name: '',
        phone: '',
        surveyResults: '',
        city: '',
        address: '',
        appointmentTime: '',
        serviceStar: '',
        provinceCode: '',
        cityCode: ''
      },
      picker: {
        show: false,
        columns: [],
        currKey: '',
        dataCollection: {
          orderNo: [],
          surveyResults: [{ 'value': '1', 'label': '可安装' }, { 'value': '2', 'label': '不可安装' }, { 'value': '3', 'label': '未知' }],
          city: [],
          serviceStar: []
        }
      },
      appointmentTime: {
        show: false,
        currentDate: new Date(new Date().getTime() + 172800000), // 日期提供未来60天的选择（不含今天及明天）
        minDate: new Date(new Date().getTime() + 172800000),
        maxDate: new Date(new Date().getTime() + 5184000000),
      },
      step: 0,
      orderInfo: {},
      selectOrderCompletion: false,
      isAm: true,
      defaultDay: this.formatDate(new Date()),// 默认日期，不填是本日
      disabledDay: [],// 不可用日期
      currentTime: '',
      surveyList: [] // 创建过勘测单的订单号列表
    }
  },
  watch: {
    // 观察选中日期
    'formData.appointmentTime': {
      handler: function (newVal) {
        console.log('newval', newVal);

        this.formData.appointmentTime = newVal

        this.formData.serviceStar = ''
        // this.formData.serviceStar.value = ''
      },
      immediately: true
    }
  },
  methods: {
    // 获取订单列表
    async getOrderList () {
      /* const toast = Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner'
      }) */
      this.picker.dataCollection.orderNo = []
      const res = await orderList({
        channel: this.$storage.get('channel'), page: { size: 10, index: 1 }, uidList: [{ uid: this.uid }]
      })
      if (!res) return
      if (res.success) {
        const list = []
        res.data.pgdataList.forEach(item => {
          // 过滤出满足需求的订单号列表
          if (!['01', '03', '04', '05', '06', '10', '99'].includes(item.status)) {
            list.push(item.orderNum)
            this.picker.dataCollection.orderNo = [...list]
            this.loading = false
          }
        })
        // toast.clear()
      } else {
        Toast(res.msg)
      }
    },
    // 获取勘测单
    async getOrderMessage () {
      const res = await orderMessage({
        uid: this.uid
      })
      if (!res) return
      if (res.success) {
        this.surveyList = res.data.list.map(el => {
          return el.orderNo
        })
      } else {
        Toast('请求数据失败')
      }
    },
    // 获取订单详情
    async getOrderDetal () {
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const res = await getDetail({ orderNum: this.formData.orderNo, channel: this.$storage.get('channel') })
      Toast.clear()
      if (!res) return
      if (res.success) {
        this.orderInfo = res.data
        this.orderInfo.status = orderStatusAttr[res.data.status]
        this.selectOrderCompletion = true
      } else {
        Toast(res.msg)
      }
    },
    // 获取省市区数据
    async getAreaTree () {
      this.loading = true
      const res = await getAreaTree()
      if (!res) return
      if (res.success) {
        this.picker.dataCollection.city = [...res.data]
        this.loading = false
      } else {
        Toast(res.msg)
      }
    },
    // 获取服务明星
    async getAllStart () {
      this.loading = true
      const res = await allStart({ 'provinceCode': this.formatDate.provinceCode, 'cityCode': this.formatDate.cityCode })
      if (!res) return
      if (res.success) {
        this.picker.dataCollection.serviceStar = res.data.map(item => {
          return {
            'value': item.id,
            'label': item.name
          }
        })
        this.loading = false
      } else {
        Toast(res.msg)
      }
    },
    onCancel () {
      this.$router.go(-1)
    },
    lastStep () {
      if (this.step > 0) {
        this.step--
      }
    },
    nextStep () {
      if (this.step < 2) {
        if (this.step == 0) {
          if (this.formData.orderNo === '') {
            Toast('请选择订单号')
            return
          }
        }
        if (this.step === 1) {
          if (this.formData.name === '') {
            Toast('Please enter联系人姓名')
            return
          }
          if (this.formData.phone === '') {
            Toast('Please enter联系人电话')
            return
          }
          if (this.formData.surveyResults === '') {
            Toast('请选择勘测结果')
            return
          }
          if (this.formData.city === '') {
            Toast('请选择所在地区')
            return
          }
          if (this.formData.address === '') {
            Toast('Please enter详细地址')
            return
          }
        }
        this.step += 1
      }
    },
    onSave () {
      if (this.formData.appointmentTime === '') {
        Toast('请选择预约时间')
        return
      }
      if (this.formData.serviceStar === '') {
        Toast('请选择服务明星')
        return
      }
      /* Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      }) */
      const obj = {
        orderNo: this.orderInfo.orderNum,
        uid: this.orderInfo.uid,
        superId: this.orderInfo.superId,
        vehicleOwnerName: this.orderInfo.name, // 下单人姓名
        vehicleOwnerTel: this.orderInfo.mobile, // 下单人联系电话
        contactName: this.formData.name, // 联系人姓名
        contactTel: this.formData.phone, // 联系电话
        // contactSex: this.formData.gender, // 性别
        surveyResults: this.formData.surveyResults.value, // 勘测结果
        province: this.formData.city.split(',')[0],
        provinceCode: this.formData.provinceCode, // 省code
        city: this.formData.city.split(',')[1],
        cityCode: this.formData.cityCode, // 市code
        district: this.formData.city.split(',')[2],
        districtCode: this.formData.regionCode, // 区code
        address: this.formData.address, // 详细地址
        serviceStarId: this.formData.serviceStar.value, // 服务明星ID
        surveyVisitTime: dayjs(this.formData.appointmentTime).format('YYYY-MM-DD HH:mm:ss'), // 预约勘测时间
        source: 'EOS' // 工单来源
      }
      createSurvey(obj).then(res => {
        Toast.clear()
        if (res.success) {
          this.$router.push({
            path: 'surveyInfo',
            query: {
              'installOrderNo': res.data.installOrderNo,
              // 如果页面有source参数就说明是从订单详情页面创建过来的，否则就是从客户详情页面创建过来的
              'source': this.$route.query ? this.$route.query.source : 'userInfo'
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    },
    pickerShow (key) {
      this.picker.currKey = key
      this.picker.show = true
      this.picker.columns = [...this.picker.dataCollection[key]]
    },
    pickerConfirm (value) {
      this.picker.show = false
      this.formData[this.picker.currKey] = value
      if (this.picker.currKey === 'orderNo') {
        // 选择订单号的时候判断当前的订单号是否已经创建了勘测单
        if (this.surveyList.includes(value)) {
          Toast('该订单号已创建勘测单，请更换订单号')
          this.formData['orderNo'] = ''
        } else {
          this.getOrderDetal()
        }
      }
      if (this.picker.currKey === 'city') {
        this.formData.city = value.join(',')
        const selected = this.findPathByLabels(this.picker.dataCollection.city, value)
        this.formData['provinceCode'] = selected[0].value
        this.formData['cityCode'] = selected[1].value
        this.formData['regionCode'] = selected[2].value
        this.getAllStart()
      }
    },
    appointmentTimeConfirm (value) {
      this.formData.appointmentTime = `${this.formatDate(value)}`
      this.appointmentTime.show = false
    },
    formatDate (date) {
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      return y + '-' + m + '-' + d
    },
    changeAppointmentTimePicker (picker, value) {
      this.appointmentTime.columnsValue = value
    },
    findPathByLabels (treeData, labelArr) {
      const res = []
      const dfs = (treeData) => {
        if (!labelArr.length) return
        if (Array.isArray(treeData)) {
          for (const data of treeData) {
            const idx = labelArr.indexOf(data.label)
            if (idx > -1) {
              res.push(data)
              labelArr.splice(idx, 1)
            }
            dfs(data.children)
          }
        }
      }
      dfs(treeData)
      return res
    },
    init () {
      // 如果有传订单号就说明是从订单详情过来的否则就是从客户详情过来的
      if (this.$route.query && this.$route.query.orderNum) {
        this.formData.orderNo = this.$route.query.orderNum
        this.uid = this.$route.query.uid
        this.picker.dataCollection.orderNo = [this.formData.orderNo]
        this.getOrderDetal()
      } else {
        this.uid = this.$route.query.uid
        this.getOrderList()
      }
      this.getOrderMessage()
      this.getAreaTree()
    }
  },
  mounted () {
    this.init()
  }
}

</script>
