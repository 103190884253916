<template>
  <div class="footer">
    <div class="footer-wrap space-between createSurvey">
      <Button v-if="step === 0"
              @click="$emit('on-cancel')"
              size="small"
              type="info"
              style="margin-right: 20px;"
              plain
              round>取消</Button>
      <Button v-if="step > 0"
              @click="$emit('on-last')"
              size="small"
              type="info"
              style="margin-right: 20px;"
              plain
              round>上一步</Button>
      <Button v-if="step !== maxStep"
              @click="$emit('on-next')"
              size="small"
              type="info"
              class="next-btn"
              round>下一步</Button>
      <Button v-if="step == maxStep"
              @click="$emit('on-save')"
              size="small"
              type="info"
              class="next-btn"
              round>提交</Button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
export default {
  components: {
    Button
  },
  props: {
    step: {
      type: Number,
      default: 0
    },
    maxStep: {
      type: Number,
      default: 0
    }
  }
}
</script>
<style lang="less" scoped>
.footer {
  height: 82px;
  line-height: 82px;
  font-size: 32px;
  padding-bottom: 25px;
  .footer-wrap {
    padding: 24px;
    height: 100%;
    align-items: flex-start;
    &.createSurvey{
      white-space: nowrap;
    }
    .price {
      font-weight: 800;
      color: #ff2e2d;
      margin-left: 10px;
    }

    /deep/ .van-button--plain {
      border: 2px solid #DA3738;
      background-color: transparent !important;
    }

    & > div {
      line-height: 1;
      color: #DA3738;
    }

    & > div /deep/ .van-button {
      width: 1.973rem;
      height: 0.773rem;
      border-radius: 0.107rem;
      background-color: #DA3738;
      margin-left: 0.24rem;
    }
    .next-btn {
      width: 80% !important;
    }
  }
}
</style>